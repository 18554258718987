<template>
    <div class="dashboard-logged mainWrapper bg-dark">
        <section class="blockElement masterPips pb-3">
            <div class="container max-1370">
                <div class="row">
                    <div class="col-12 py-4">
                        
                        <p class="text-white">Trading spot currencies involves substantial risk and there is always the potential for loss. Your trading results may vary. Because the risk factor is high in the foreign exchange market trading, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in the foreign exchange market. Forex Brokers and ZuluTrade are compensated for their services through the spread between the bid/ask prices or there may be a cost to initiate a trade through the bid/ask spread. Profit sharing accounts are subject to a monthly performance fee per selected trading system.</p>
                        <p class="text-white">Hypothetical performance results have many inherent limitations, some of which are described below. No representation is being made that any account will or is likely to achieve profits or losses similar to those shown. In fact, there are frequently sharp differences between hypothetical performance results and the actual results subsequently achieved by any particular trading program.</p>
                        <p class="text-white">One of the limitations of hypothetical performance results is that they are generally prepared with the benefit of hindsight. In addition, hypothetical trading does not involve financial risk, and no hypothetical trading record can completely account for the impact of financial risk in actual trading. For example, the ability to withstand losses or to adhere to a particular trading program in spite of trading losses are material points which can also adversely affect actual trading results. There are numerous other factors related to the markets in general or to the implementation of any specific trading program which cannot be fully accounted for in the preparation of hypothetical performance results and all of which can adversely affect actual trading results. Please click <a class="link" href="/risk-disclaimer">here</a> to see full disclaimer.</p>
                        <p class="text-white">Forex Brokers and ZuluTrade are compensated for their services through the spread between the bid/ask prices or there may be a cost to initiate a trade through the bid/ask spread. 
                Profit sharing accounts are subject to a monthly subscription fee and a monthly performance fee per selected trading system.</p>


                    </div>
                </div>
            </div>
        </section>
    </div>
</template>